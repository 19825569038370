.container {
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content:center;
	align-items: center;
}
.images {
	margin: 20px 0px;
	max-width: 100%;
	overflow: hidden;
	object-fit: contain;
}

.form {
	margin: 20px 0px 0px 0px;
	max-width: 350px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
}

.form__line {
	margin: 0px 0px 20px 0px;
	max-width: 450px;
	width: 100%;
	z-index: 100;
	background-color: #fff;
}
.form__line h3 {
	text-align: center;
	margin: 0px 0px 15px 0px;
}
.form__line article {
	padding: 0 10px;
	font-size: 16px;
	text-align: center;
	font-weight: 600;
	line-height: 25px;
	color: var(--grey-color);
	margin: 0px 0px 25px 0px;
}
.form__line input {
	width: 100%;
	padding: 15px 20px;
	font-size: 16px;
	font-weight: 600;
	border: 2px solid var(--lightGrey-color);
	border-radius: 8px;
	background-color: #fff;
	/*color: var(--lightGrey-color);*/
	color: var(--primary-color)
}
.error input{
	border: 2px solid red !important;
	outline-color: red !important;
}

.form__line input::placeholder {
	font-weight: 400;
}
.form__line label, .form__line h5 {
	margin: 0px 0px 5px 0px;
	color: var(--primary-color);
	font-weight: bold;
}
.form__line p {
	margin: 10px 0px 0px 0px;
	font-size: 22px;
	color: var(--primary-color);
	letter-spacing: 2px;
	text-align: center;
	font-family: Arial, Helvetica, sans-serif;
}
.form__line h5 {
	font-size: 16px;
	/*text-align: center;*/
}
.resend p {
	margin: 45px 0px 10px 0px;
	font-weight: 600;
	color: var(--primary-color);
	text-align: center;
}
.resend p span {
	color: #0566d5;
	cursor: pointer;
}


.button {
	margin: 10px auto 0 auto;
	padding: 13px 35px;
	width: fit-content;
	background-color: var(--primary-color);
	color: #fff;
	border-radius: 8px;
	font-size: 15px;
	cursor: pointer;
}
.button:hover {
	opacity: 0.7;
	transition: all 0.5s;
}
.button:disabled {
	opacity: 0.7;
}