
@font-face {
	font-family: "Raleway";
	src: local("RalewayRegular"),
		url("./fonts/Raleway-Regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayMedium"),
		url("./fonts/Raleway-Medium.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayLight"),
		url("./fonts/Raleway-Light.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayLightIt"),
		url("./fonts/Raleway-LightItalic.ttf") format("truetype");
	font-weight: 300;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayBold"),
		url("./fonts/Raleway-Bold.ttf") format("truetype");
	font-weight: 700;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayBlack"),
		url("./fonts/Raleway-Black.ttf") format("truetype");
	font-weight: 900;
}

@font-face {
	font-family: "Raleway";
	src: local("RalewayExtra"),
		url("./fonts/Raleway-ExtraBold.ttf") format("truetype");
	font-weight: 800;
}

* {
	margin: 0;
	padding: 0;
	text-decoration: none;
	box-sizing: border-box;
}

body {
	--primary-color: #012043;
	--lightGrey-color: #C6CDD5;
	--grey-color: #606A7B;
	font-family: 'Raleway', sans-serif;
	color: var(--primary-color);
}

.screen,
#root {
	z-index: -1;
}

.screen {
	padding: 10px;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.myIcon {
	width: 100%;
	height: auto;
}

/*.p-button {
	background-color: var(--primary-color);
	color: #fff;
	padding: 10px 30px;
}*/

.p-menubar {
	border: 0px solid #e5e5e5 !important;
	border-radius: 0px !important;
}

.p-menubar .p-menubar-button {
	display: none !important;
}
.p-skeleton {
	margin: 0px 0px 10px 0px;
}
.p-toast {
	width: 300px !important;
}
.error {
	color: var(--red-500);
	margin: 0px 0px 10px 0px !important;
}

.react-tel-input .form-control {
	width: 100% !important;
	height: 52px !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	border: 2px solid var(--lightGrey-color) !important;
	border-radius: 8px !important;
	background-color: #fff !important;
	/*color: var(--lightGrey-color);*/
	color: var(--primary-color) !important;
}

.react-tel-input .form-control.invalid-number {
	border: 2px solid #d79f9f !important;
	background-color: #FAF0F0 !important;
	border-left-color: #cacaca !important;
}