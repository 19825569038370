.container p {
margin: 0px 0px 20px 0px;
}
.container {
	margin: 20px 0px 0px 0px;
}
.wrap {
	margin: 0px 0px 10px 0px;
}

@media (min-width: 500px){
	.wrap {
		display: flex;
		/*justify-content:center;*/
		align-items: center;
	}
}

.link {
	position: relative;
	/*background-color: #f6f6f6;*/
	background-color: var(--gray-100);
	padding: 10px 90px 10px 20px;
	cursor: pointer;
	margin: 0px 10px 10px 0px;
}
.copy {
	position: absolute;
	/*top: 10px;
	right: 15px;*/
	top: 0;
	right: 0;
}
.form {
	margin: 0px 0px 10px 0px;
}